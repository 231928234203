let initialState = {
  status: false,
  sidebarShow: 'responsive',
  fullScreen: false,
};

let globalLoading = (state = initialState, action) => {
  switch (action.type) {
    case 'CONTROL_LOADING':
      state = {
        status: action.status,
      };
      return state;
    case 'set':
      state = {
        sidebarShow: action.sidebarShow,
      };
      return state;
    case 'fullscreen':
      state = {
        fullScreen: action.fullScreen,
      };
      return state;
    default:
      return state;
  }
};

export default globalLoading;
