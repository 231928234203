import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {icons} from './assets/icons';
import {Provider} from 'react-redux';
import store from './redux/store';
import axios from 'axios';
import {setupAxios} from './helpers/apiHelper';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
// import {getTokenData} from 'src/firebase';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const queryClient = new QueryClient();

setupAxios(axios, store);

React.icons = icons;

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// getTokenData();
serviceWorker.unregister();
