import React, {useState, useEffect, useRef, memo} from 'react';
import CIcon from '@coreui/icons-react';
import Moment from 'react-moment';
import 'moment-timezone';

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CButton,
  CForm,
  CInput,
  CTooltip,
  CRow,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
} from '@coreui/react';
import requestApi from 'src/helpers/apiHelper';
import useOutsideClick from 'src/helpers/useOutsideClick';
import {
  NOTIFICATION_CLASSIFY,
  API_ENDPOINTS_HOST,
  PROCESSING_STATUS,
  NOTIFICATION_TYPE_ENDPOINT,
} from 'src/constants';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

const NotificationMerchant = (props) => {
  const {setFetchUnreadInfo} = props;
  const ref = useRef();
  const scrollDivRef = useRef(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 20;
  const [keywordSearch, setKeywordSearch] = useState('');
  const [isShowFilterTime, setIsShowFilterTime] = useState(false);
  const [strSearch, setStrSearch] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [numberOfPage, setNumberOfPage] = useState(0);
  const [data, setData] = useState([]);

  const [reloadData, setReloadData] = useState(0);
  const [tabName, setTabName] = useState('ALL');
  const [tabData, setTabData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [reloadUnreadDataCount, setReloadUnreadDataCount] = useState(false);

  // control Sort
  useEffect(() => {
    const initData = async () => {
      if (strSearch === '' || strSearch.length > 2) {
        setIsLoadingData((pre) => !pre);
        let params = {
          pageIndex: 1,
          pageSize: itemPerPage,
          searchText: strSearch,
          sortBy: [
            {
              fieldName: 'UpdatedTime',
              ascending: false,
            },
          ],
          classify: NOTIFICATION_CLASSIFY.MERCHANT_TO_DPOINT,
          notificationTypeId: tabName === 'ALL' ? '' : tabName,
        };
        // if (keywordSearch.length > 0) {
        //   params.searchText = keywordSearch;
        // }
        await requestApi(
          API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY,
          '/api/user-system-notification/dpoint-portal/search',
          'POST',
          params
        )
          .then((response) => {
            setData(response.data.data.data.data);
            setTotalRows(response.data.data.data.numOfRecords);
            setIsLoadingData((pre) => !pre);
            setCurrentPage(1);
            setNumberOfPage(response.data.data.data.numOfPages);
          })
          .catch((error) => {
            console.log(error);
            setIsLoadingData((pre) => !pre);
          });
      }
    };
    initData();
  }, [strSearch, tabName, reloadData]);

  useEffect(() => {
    let unmounted = true;
    if (unmounted) {
      if (!isFetching) return;
      fetchMoreListItems();
    }

    return () => {
      unmounted = false;
    };
  }, [isFetching]);

  const fetchMoreListItems = async () => {
    if (!navigator.onLine) {
      toast.error('Đã có lỗi xảy ra. Vui lòng kiểm tra đường truyền internet hoặc thử lại sau.', {
        position: 'bottom-center',
        autoClose: false,
        style: {width: '550px'},
      });
    } else {
      let pageIndex = currentPage + 1;
      if (numberOfPage >= pageIndex) {
        let params = {
          pageIndex: pageIndex,
          pageSize: itemPerPage,
          searchText: keywordSearch,
          sortBy: [
            {
              fieldName: 'UpdatedTime',
              ascending: false,
            },
          ],
          classify: NOTIFICATION_CLASSIFY.MERCHANT_TO_DPOINT,
          notificationTypeId: tabName === 'ALL' ? '' : tabName,
        };

        await requestApi(
          API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY,
          '/api/user-system-notification/dpoint-portal/search',
          'POST',
          params
        )
          .then((response) => {
            setData([...data, ...response.data.data.data.data]);
            setIsFetching(false);
            setCurrentPage(pageIndex);
            setNumberOfPage(response.data.data.data.numOfPages);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    const getNotificationTabs = async () => {
      await requestApi(
        API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY,
        `/api/notification-type/all-with-classify/${NOTIFICATION_CLASSIFY.MERCHANT_TO_DPOINT}`,
        'GET'
      )
        .then((response) => {
          if (response.data.isSuccess) {
            let tabDataList = [
              {
                name: 'Tất cả',
                value: 'ALL',
                numberOfRecord: response.data.data
                  .map((x) => x.numberOfRecord)
                  .reduce((accumulator, current) => accumulator + current, 0),
              },
            ];
            response.data.data.map((x) =>
              tabDataList.push({
                name: x.name,
                value: x.id,
                numberOfRecord: x.numberOfRecord,
              })
            );
            setTabData(tabDataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getNotificationTabs();
  }, [reloadUnreadDataCount]);

  useOutsideClick(ref, () => {
    setIsShowFilterTime(false);
    console.log(isShowFilterTime);
  });

  const handleExpandedProcessingStatus = (id) => {
    setData(
      data.map((item) => {
        if (item.id === id) {
          return {...item, isCollapsed: !item.isCollapsed};
        } else {
          return item;
        }
      })
    );
  };

  const handleUpdateReadStatus = async (id, isNavigation) => {
    if (isNavigation && data.find((x) => x.id === id)?.readStatus === 1) {
      return;
    } else {
      try {
        const response = await requestApi(
          API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY,
          `/api/user-system-notification/read-status/update/${id}`,
          'PUT'
        );
        if (response.data.isSuccess) {
          setReloadUnreadDataCount((pre) => !pre);
          let notificationData = data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                readStatus: item.readStatus === 0 ? 1 : 0,
                isShowUpdateReadStatusBtn: false,
              };
            } else {
              return item;
            }
          });
          setFetchUnreadInfo((pre) => !pre);
          setData(notificationData);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdateAllReadStatus = async () => {
    try {
      const response = await requestApi(
        API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY,
        `/api/user-system-notification/read-status/update-all/${NOTIFICATION_CLASSIFY.MERCHANT_TO_DPOINT}`,
        'PUT'
      );
      if (response.data.isSuccess) {
        setReloadUnreadDataCount((pre) => !pre);
        setFetchUnreadInfo((pre) => !pre);
        setReloadData((pre) => pre + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSearch = (event) => {
    setStrSearch(event.target.value);
  };

  const handleScroll = () => {
    if (
      Math.round(
        document.getElementById('scroll-store').scrollTop +
          document.getElementById('scroll-store').offsetHeight
      ) +
        10 >=
      document.getElementById('scroll-store').scrollHeight
    ) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }

    return;
  };

  const handleRemoveText = () => {
    setStrSearch('');
  };

  return (
    <CRow className="custom-popover-notification">
      <CCol xl={12} style={{padding: 0}}>
        <CCard className="custom-card-notification">
          <CCardHeader className="custom-card-header-notification">
            <CRow>
              <CCol sm={1} style={{marginTop: 12}}>
                <span
                  onClick={() => {
                    setReloadData(reloadData + 1);
                    setReloadUnreadDataCount((pre) => !pre);
                    setFetchUnreadInfo((pre) => !pre);
                  }}
                  style={{marginTop: 12, marginLeft: 10}}
                >
                  <i
                    className="fa fa-refresh"
                    aria-hidden="true"
                    style={{fontSize: 20, color: 'blue', cursor: 'pointer'}}
                  ></i>
                </span>
              </CCol>
              <CCol sm={6}>
                {strSearch.length === 0 ? (
                  <i className="custom-search-icon-notification" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-times-circle custom-search-icon-notification"
                    aria-hidden="true"
                    style={{fontSize: 15}}
                    onClick={handleRemoveText}
                  ></i>
                )}
                <CInput
                  type="text"
                  value={strSearch}
                  style={{
                    height: '40px',
                    cursor: '',
                    borderRadius: 20,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                  name="txtSearch"
                  onChange={onChangeSearch}
                />

                <div
                  style={{
                    position: 'absolute',
                    left: 23,
                    top: 10,
                    zIndex: 1000,
                    cursor: 'pointer',
                  }}
                >
                  <i className="fa fa-search" aria-hidden="true" style={{fontSize: 18}}></i>
                </div>
              </CCol>
              <CCol sm={4} style={{padding: 0}}>
                <CButton
                  className="btn btn-info mt-1 float-right"
                  disabled={tabData.find((x) => x.value === 'ALL')?.numberOfRecord === 0}
                  onClick={handleUpdateAllReadStatus}
                >
                  Đánh dấu đã đọc tất cả
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody className="custom-ccard-body">
            <CTabs activeTab="ALL">
              <CNav variant="tabs">
                {tabData.map((x) => (
                  <CNavItem>
                    <CNavLink
                      onClick={() => {
                        setTabName(x.value);
                        scrollDivRef.current.scrollTo(0, 0);
                      }}
                      data-tab={x.value}
                    >
                      {x.name} {' ('}
                      {x.numberOfRecord}
                      {')'}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <div
                className="custom-card-body-notification"
                onScroll={handleScroll}
                id="scroll-store"
                ref={scrollDivRef}
              >
                {data !== null && data?.length > 0 ? (
                  data.map((x) => {
                    return x.isAssign ? (
                      <CCol
                        sm={12}
                        style={{borderBottom: '1px solid', borderColor: '#c4c9d0'}}
                        className={x.readStatus === 0 ? 'custom-read-status-div' : ''}
                      >
                        <CRow className="mb-2">
                          <div style={{width: 15, marginLeft: 15}}>
                            <span
                              onClick={() => {
                                handleExpandedProcessingStatus(x.id);
                              }}
                            >
                              {x.notificationProcessings.length > 1 ? (
                                !x.isCollapsed ? (
                                  <i className="fa fa-chevron-down"></i>
                                ) : (
                                  <i className="fa fa-chevron-up"></i>
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          <CCol sm={8}>
                            <div style={{fontWeight: 400, color: 'black'}}>
                              <span dangerouslySetInnerHTML={{__html: x.content}} />
                            </div>
                          </CCol>
                          <CCol sm={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div>
                              <Moment className="badge" format="HH:mm DD/MM/YYYY">
                                {x.createdTime}
                              </Moment>
                            </div>
                            <span
                              style={{cursor: 'pointer'}}
                              onClick={() => handleUpdateReadStatus(x.id)}
                            >
                              <CTooltip
                                content={
                                  x.readStatus === 0 ? 'Đánh đấu đã đọc' : 'Đánh dấu chưa đọc'
                                }
                              >
                                <i
                                  className="fa fa-eye mx-2"
                                  aria-hidden="true"
                                  style={{color: x.readStatus === 0 ? 'gray' : 'black'}}
                                ></i>
                              </CTooltip>
                            </span>
                          </CCol>
                        </CRow>
                        <CRow className="mb-2">
                          <div style={{width: 15, marginLeft: 15}}></div>
                          <CCol sm={6}>
                            <Link
                              to={
                                x.notificationCode === 'MERCHANT_ACCOUNT'
                                  ? `/merchant-admin-register/${x.notificationItemId}/edit`
                                  : NOTIFICATION_TYPE_ENDPOINT.find(
                                      (z) => z.id === x.notificationCode
                                    )?.value + x.notificationItemId
                              }
                              onClick={() => {
                                handleUpdateReadStatus(x.id, true);
                              }}
                              target="_blank"
                            >
                              <div style={{fontWeight: 400, color: 'black'}}>
                                {x.notificationItemName}
                              </div>
                            </Link>
                          </CCol>
                          <CCol sm={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div>
                              <div>
                                {tabData.find((z) => z.value === x.notificationTypeId)?.name}
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                        {x.notificationProcessings.map((z, key) => {
                          if (key === 0) {
                            return (
                              <CRow>
                                <CCol sm={6} style={{marginLeft: 30, paddingRight: 0}}>
                                  <div
                                    className={`badge text-wrap ${
                                      PROCESSING_STATUS.find((a) => a.id === z.processing)?.color
                                    }`}
                                  >
                                    {PROCESSING_STATUS.find((a) => a.id === z.processing)?.label}
                                  </div>
                                </CCol>
                                <CCol sm={5}>
                                  <div className="text-right">
                                    <Moment className="badge" format="HH:mm DD/MM/YYYY">
                                      {z.createdTime}
                                    </Moment>
                                  </div>
                                </CCol>
                              </CRow>
                            );
                          } else {
                            return (
                              x.isCollapsed && (
                                <CRow>
                                  <CCol sm={6} style={{marginLeft: 30, paddingRight: 0}}>
                                    <div className="badge">
                                      {PROCESSING_STATUS.find((a) => a.id === z.processing)?.label}
                                    </div>
                                  </CCol>
                                  <CCol sm={5}>
                                    <div className="text-right">
                                      <Moment className="badge" format="HH:mm DD/MM/YYYY">
                                        {z.createdTime}
                                      </Moment>
                                    </div>
                                  </CCol>
                                </CRow>
                              )
                            );
                          }
                        })}
                        <CCol sm={6} style={{marginLeft: 15}}>
                          <div className="badge">{x.merchantName}</div>
                        </CCol>
                        <div className="mb-2"></div>
                      </CCol>
                    ) : (
                      <CCol
                        sm={12}
                        style={{borderBottom: '1px solid', borderColor: '#c4c9d0'}}
                        className={x.readStatus === 0 ? 'custom-read-status-div' : ''}
                      >
                        <CRow className="mb-2">
                          <div style={{width: 15, marginLeft: 15}}>
                            <span
                              onClick={() => {
                                handleExpandedProcessingStatus(x.id);
                              }}
                            >
                              {x.notificationProcessings.length > 1 ? (
                                !x.isCollapsed ? (
                                  <i className="fa fa-chevron-down"></i>
                                ) : (
                                  <i className="fa fa-chevron-up"></i>
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          <CCol sm={6}>
                            <Link
                              to={
                                x.notificationCode === 'MERCHANT_ACCOUNT'
                                  ? `/merchant-admin-register/${x.notificationItemId}/edit`
                                  : NOTIFICATION_TYPE_ENDPOINT.find(
                                      (z) => z.id === x.notificationCode
                                    )?.value + x.notificationItemId
                              }
                              onClick={() => {
                                handleUpdateReadStatus(x.id, true);
                              }}
                              target="_blank"
                            >
                              <div style={{fontWeight: 400, color: 'black'}}>
                                {x.notificationItemName !== null && x.notificationItemName !== ''
                                  ? x.notificationItemName
                                  : x.title}
                              </div>
                            </Link>
                          </CCol>
                          <CCol sm={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div>{tabData.find((z) => z.value === x.notificationTypeId)?.name}</div>
                            <span
                              style={{cursor: 'pointer'}}
                              onClick={() => handleUpdateReadStatus(x.id)}
                            >
                              <CTooltip
                                content={
                                  x.readStatus === 0 ? 'Đánh đấu đã đọc' : 'Đánh dấu chưa đọc'
                                }
                              >
                                <i
                                  className="fa fa-eye mx-2"
                                  aria-hidden="true"
                                  style={{color: x.readStatus === 0 ? 'gray' : 'black'}}
                                ></i>
                              </CTooltip>
                            </span>
                          </CCol>
                        </CRow>
                        {x.notificationProcessings.map((z, key) => {
                          if (key === 0) {
                            return (
                              <CRow>
                                <CCol sm={6} style={{marginLeft: 30, paddingRight: 0}}>
                                  <div
                                    className={`badge text-wrap ${
                                      PROCESSING_STATUS.find((a) => a.id === z.processing)?.color
                                    }`}
                                  >
                                    {PROCESSING_STATUS.find((a) => a.id === z.processing)?.label}
                                  </div>
                                </CCol>
                                <CCol sm={5}>
                                  <div className="text-right">
                                    <Moment className="badge" format="HH:mm DD/MM/YYYY">
                                      {z.createdTime}
                                    </Moment>
                                  </div>
                                </CCol>
                              </CRow>
                            );
                          } else {
                            return (
                              x.isCollapsed && (
                                <CRow>
                                  <CCol sm={6} style={{marginLeft: 30, paddingRight: 0}}>
                                    <div className="badge">
                                      {PROCESSING_STATUS.find((a) => a.id === z.processing)?.label}
                                    </div>
                                  </CCol>
                                  <CCol sm={5}>
                                    <div className="text-right">
                                      <Moment className="badge" format="HH:mm DD/MM/YYYY">
                                        {z.createdTime}
                                      </Moment>
                                    </div>
                                  </CCol>
                                </CRow>
                              )
                            );
                          }
                        })}
                        <CCol sm={6} style={{marginLeft: 15}}>
                          <div className="badge">{x.merchantName}</div>
                        </CCol>
                        <div className="mb-2"></div>
                      </CCol>
                    );
                  })
                ) : (
                  <CCol sm={12} className="text-center mt-3">
                    <span>Không có kết quả phù hợp</span>
                  </CCol>
                )}
              </div>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default memo(NotificationMerchant);
